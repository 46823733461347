// import * as jQuery from "jquery"
import 'jquery';
import * as bootbox from 'bootbox';
// mount modal for error report:
import ReportErrorModal from './report-error-modal.js';
import { removeLoadingAnimation } from './loading-overlay.js';
import { confirmDialog } from './confirm-dialog.js';
// universal caller for server requests
const PAF = {};

// error manager:
PAF.errorManager = (status, call, noReport, noMessage, error) => {
  // always remove loading animation:
  removeLoadingAnimation();
  // first, figure out what kind of response we got:
  console.log('error: ', status, noReport, noMessage, error);
  if (noReport && !noMessage && error) {
    bootbox.alert(error);
  } else if (noReport && noMessage) {
    // do nothing
  } else if (status == 401) {
    // show dialog and offer login:
    const errorMessage = 'Your session has expired or you are not logged in.'
                + ' Would you like to log back in?';
    const functionOnConfirm = () => {
      window.location.replace(
        `/login?redir=${encodeURIComponent(window.location)}&show_redir_msg=1`,
      );
    };
    confirmDialog(errorMessage, functionOnConfirm);
  } else {
    PAF.reportError(error, call);
  }
};

// adding report error tool:

PAF.reportError = function (error, api_call) {
  // create error modal:
  const reportErrorModal = new ReportErrorModal();
  reportErrorModal.mount('.modal-space', error, api_call);
};

PAF.getProjectName = function () {
  return window.location.href.split('/')[2].split('.')[0];
};

PAF.getFunctionality = function () {
  return window.location.href.split('/').pop().split('?')[0];
};

// function for reading variables from URL
PAF.getUrlVars = function getUrlVars() {
  const vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = value;
  });
  return vars;
};

PAF.Query = async function (call, params, noReport = false, noMessage = false) {
  console.log('noReport? ', noReport, 'noMessage? ', noMessage);
  // create event that lets the timer reset:
  const apiCallEvent = new CustomEvent('apiCallEvent', { detail: 'API Call' });
  document.dispatchEvent(apiCallEvent);

  if (typeof params === 'undefined') {
    params = {};
  }

  // console.log(`/api/${call}`, JSON.stringify(params), jQuery.param(params), params);
  const fetchOptions = {
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(params),
    mode: 'cors',
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
  };
  const urlParams = PAF.getUrlVars();
  if ('client_id' in urlParams) {
    fetchOptions.headers['X-Paf-Client-Id'] = urlParams.client_id;
    fetchOptions.credentials = 'same-origin';
  }
  if ('client_secret' in urlParams) {
    fetchOptions.headers['X-Paf-Client-Secret'] = urlParams.client_secret;
    fetchOptions.credentials = 'same-origin';
  }
  if ('token' in urlParams) {
    fetchOptions.headers['X-Paf-Token'] = urlParams.token;
    fetchOptions.credentials = 'same-origin';
  }
  const response = await fetch(`/api/${call}`, fetchOptions)
    .catch((error_condition) => {
      // make sure to remove loading indicator
      removeLoadingAnimation();
      console.log('error: ', error_condition);
      PAF.errorManager(error_condition);// , call, noReport, noMessage);
    });
  if (!response.ok) {
    console.log('response not ok: ', response.status, response.statusText);
    let errorMessage;
    if (response.status < 500) {
      errorMessage = await response.json();
      // .then((jsonMessage) => {
      PAF.errorManager(response.status, call, noReport, noMessage, errorMessage);
      // });
    } else {
      errorMessage = response.statusText;
      PAF.errorManager(response.status, call, noReport, noMessage, `${response.status}: ${errorMessage}`);
    }
    return Promise.reject(errorMessage);
  }
  return response.json();
};

PAF.getUserInfo = function () {
  if (PAF.user_info) return new Promise(PAF.user_info);
  return PAF.Query('user/getUserInfo', {}, true)
    .then((user) => {
      console.log(user);
      PAF.user_info = user;
      return user;
    });
};

PAF.logout = function () {
  PAF.Query('user/logout')
    .then(() => {
      window.location.replace(`/login?redir=${encodeURIComponent(window.location)}&show_redir_msg=1`);
    });
};

export default PAF;
