import ReportErrorTemplate from '../views/report-error-modal.pug';
import PAF from 'shared/js/paf.js';

export default class ReportErrorModal {
  mount(paf_modal_area, error, api_call) {
    console.log('reportErrorModal: ', error);
    this.$modal_space = $(paf_modal_area);
    const reportErrorTemplate = ReportErrorTemplate({
      error,
      api_call,
    });

    this.$modal_space.append(reportErrorTemplate);
    this.$reportErrorModal = this.$modal_space.find('.reportErrorModal');
    this.$userComments = this.$reportErrorModal.find('.user_report');
    this.$dismissError = this.$reportErrorModal.find('.dismiss-error-button');
    this.$closeModal = this.$reportErrorModal.find('.close');
    this.$reloadPage = this.$reportErrorModal.find('.reload-page-button');

    this.$reportErrorModal.modal('show');

    this.reportId = null;
    const report = this.generateReport(api_call, error);
    report.user_comments = this.$userComments.val();
    // First we always report even if not submitted, noting the id.
    // Note we don't report on an error with this call to prevent an infinite
    // loop
    PAF.Query('log/submitErrorReport', report, true)
      .then((id) => {
        this.reportId = id;
      });
    this.$dismissError.click(() => {
      this.maybeSubmitReport(api_call, error);
      this.$reportErrorModal.modal('hide');
      this.$reportErrorModal.remove();
    });
    this.$closeModal.click(() => {
      this.$reportErrorModal.modal('hide');
      this.$reportErrorModal.remove();
    });
    this.$reloadPage.click(() => {
      this.maybeSubmitReport(api_call, error, true);
    });
  }

  maybeSubmitReport(api_call, error, reload) {
    const userComments = this.$userComments.val();
    if (userComments) {
      console.log('submitting report!');
      const report = this.generateReport(api_call, error);
      report.user_comments = userComments;
      report.id = this.reportId;
      PAF.Query('log/submitErrorReport', report, true)
        .then(() => {
          if (reload) {
            window.location.reload();
          }
        });
    } else if (reload) {
      window.location.reload();
    }
  }

  generateReport(api_call, error) {
    const report = {
      location: window.location.href,
      api_call,
      message: error,
    };
    return report;
  }
}
