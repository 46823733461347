// confirmation dialog
export const confirmDialog = (msg, yes_callback, no_callback = null) => {
  $('#confirmModal').remove();
  // confirmation modal
  $('<div class="modal" id="confirmModal" tabindex="-1" role="dialog"></div>').appendTo('body')
    .html(`<div class="modal-dialog modal-lg" role="document"><div class="modal-content"><div class="modal-header"><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body">${msg}</div><div class="modal-footer"><button id="confirm-button" class="btn btn-primary"> Yes </button><button id="no-button" class="btn btn-primary"> No </button></div></div></div></div>`);
  $('#confirmModal').modal('show');
  $('#confirm-button').click(() => {
    if (yes_callback) yes_callback();
    $('#confirmModal').modal('hide');
    $('#confirmModal').remove();
  });
  $('#no-button').click(() => {
    if (no_callback) no_callback();
    $('#confirmModal').modal('hide');
    $('#confirmModal').remove();
  });
};
