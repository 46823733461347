function pug_attr(t,e,n,r){if(!1===e||null==e||!e&&("class"===t||"style"===t))return"";if(!0===e)return" "+(r?t:t+'="'+t+'"');var f=typeof e;return"object"!==f&&"function"!==f||"function"!=typeof e.toJSON||(e=e.toJSON()),"string"==typeof e||(e=JSON.stringify(e),n||-1===e.indexOf('"'))?(n&&(e=pug_escape(e))," "+t+'="'+e+'"'):" "+t+"='"+e.replace(/'/g,"&#39;")+"'"}
function pug_escape(e){var a=""+e,t=pug_match_html.exec(a);if(!t)return e;var r,c,n,s="";for(r=t.index,c=0;r<a.length;r++){switch(a.charCodeAt(r)){case 34:n="&quot;";break;case 38:n="&amp;";break;case 60:n="&lt;";break;case 62:n="&gt;";break;default:continue}c!==r&&(s+=a.substring(c,r)),c=r+1,s+=n}return c!==r?s+a.substring(c,r):s}
var pug_match_html=/["&<>]/;function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (api_call, error) {
      pug_html = pug_html + "\u003Cdiv class=\"modal reportErrorModal\" tabindex=\"-1\" role=\"dialog\"\u003E\u003Cdiv class=\"modal-dialog\" role=\"document\"\u003E\u003Cdiv class=\"modal-content\"\u003E\u003Cdiv class=\"modal-header\"\u003EAn error has occurred with this page:\u003Cbutton class=\"close\" type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\"\u003E\u003Cdiv class=\"span\" aria-hidden=\"true\"\u003E&times\u003C\u002Fdiv\u003E\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-body\"\u003E\u003Ctextarea" + (" class=\"form-control\""+" cols=\"30\""+pug_attr("readonly", true, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = error) ? "" : pug_interp)) + "\u003C\u002Ftextarea\u003E";
if (api_call)
{
pug_html = pug_html + "\u003Cdiv class=\"api-call-error\"\u003E\u003Cp\u003EWhen calling:\u003C\u002Fp\u003E\u003Ctextarea" + (" class=\"form-control\""+" cols=\"30\""+pug_attr("readonly", true, true, true)) + "\u003E" + (pug_escape(null == (pug_interp = api_call) ? "" : pug_interp)) + "\u003C\u002Ftextarea\u003E\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003Cp\u003EAn error report will be sent to the developers. If you would like to make any comments on this report, please indicate below:\u003C\u002Fp\u003E\u003Ctextarea class=\"form-control user_report\" rows=\"5\" cols=\"30\"\u003E\u003C\u002Ftextarea\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"modal-footer\"\u003E\u003Cbutton class=\"dismiss-error-button btn btn-primary\"\u003EContinue\u003C\u002Fbutton\u003E\u003Cbutton class=\"reload-page-button btn btn-primary\"\u003EReload page\u003C\u002Fbutton\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E";
    }.call(this, "api_call" in locals_for_with ?
        locals_for_with.api_call :
        typeof api_call !== 'undefined' ? api_call : undefined, "error" in locals_for_with ?
        locals_for_with.error :
        typeof error !== 'undefined' ? error : undefined));
    ;;return pug_html;};module.exports=template;